import { menus } from "./data";
import blackdiscord from "@/assets/images/blackdiscord.svg";
import blackfacebook from "@/assets/images/blackfacebook.svg";
import blacktwitter from "@/assets/images/blacktwitter.svg";
import blackyoutube from "@/assets/images/blackyoutube.svg";
import "./index.less";
import { useTranslation } from 'react-i18next';
import Language from "./language";


const Footer = () => {
  const i18n = useTranslation();
  const { t } = useTranslation();

  const renderSocial = () => {
    const socials = [
      <a key="discord" href="https://discord.gg/VqUfemnuEq" target="_blank">
        <img
          className="footProductsIcon"
          src={blackdiscord}
          alt="discordIcon"
          width={24}
        />
      </a>,
      <a
        key="facebook"
        href="https://www.facebook.com/groups/136775538422596/"
        target="_blank"
      >
        <img
          className="footProductsIcon"
          src={blackfacebook}
          alt="facebookIcon"
          width={24}
        />
      </a>,
      <a key="twitter" href="https://twitter.com/FeloApp" target="_blank">
        <img
          className="footProductsIcon"
          src={blacktwitter}
          alt="twitterIcon"
          width={24}
        />
      </a>,
      <a
        key="youtube"
        href="https://www.youtube.com/channel/UC3_iFnHLJAnJMo99NOAtnpQ"
        target="_blank"
      >
        <img
          className="footProductsIcon"
          src={blackyoutube}
          alt="youtubeIcon"
          width={24}
        />
      </a>,
    ];
    return <div className="socialPlatform">{socials}</div>;
  };

  const renderMenuItem = (id: string, to: string) => {
    const text = t(id);
    const link = (
      <a href={to} target="_blank" className="linkItem">
        {text}
      </a>
    );
    return (
      <div className="linkItemContent" key={id}>
        {link}
      </div>
    );
  };

  return (
    <div className="translatorfooter">
      <div className="footTop">
        {menus('', "").map(({ id, children }, index) => (
          <div key={id} className="itemMenu">
            <div className="title">{t(id)}</div>
            <div>
              {children?.map((child) => renderMenuItem(child.id, child.to))}
            </div>
          </div>
        ))}
      </div>
      <div className="footButtom">
        <div className="footButtomLeft">
          {t("companyinc", { year:2024 })}
        </div>
        <div className="footButtomRight">
          {renderSocial()}
          <div className="footButtomPartition">|</div>
          <Language />
        </div>
      </div>
    </div>
  );
};
export default Footer;
