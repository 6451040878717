export const menus = (locale: string, linkPrefix: string) => {
  return [
    {
      id: 'menusupport',
      children: [
        {
          id: 'menublog',
          to: 'https://prtimes.jp/main/html/rd/p/000000002.000087269.html',
        },
        {
          id: 'menujoin',
          to: 'https://discord.gg/3anTVeZnna',
        },
      ],
    },
    {
      id: 'menulaw',
      children: [
        {
          id: 'companyterms',
          to: import.meta.env.VITE_FELO_HOME + '/terms.html',
          isALink: true,
        },
        {
          id: 'companyprivacy',
          to: import.meta.env.VITE_FELO_HOME + '/privacy.html',
          isALink: true,
        },
        {
          id: 'companySCTA',
          to: import.meta.env.VITE_FELO_HOME + '/scta.html',
          isALink: true,
        },
        { id: 'menusafety', to: import.meta.env.VITE_FELO_HOME + '/security' },
      ],
    },
    {
      id: 'menucompany',
      children: [
        { id: 'menuabout', to: 'https://www.sparticle.com/ja' },
        {
          id: 'menuNews',
          to: 'https://news.felo.me/',
        },
      ],
    },
    {
      id: 'menuproduct',
      children: [
        {
          id: 'menutranslator',
          to: (import.meta.env.VITE_TRANSLATOR_URL as string) + '/' + (locale === 'en' ? '' : locale),
        },
        {
          id: 'menumeet',
          to: (import.meta.env.VITE_FELO_MEET as string) + '/' + (locale === 'en' ? '' : locale),
        },
        {
          id: 'menusubtitle',
          to: import.meta.env.VITE_FELO_SUBTITLES as string,
        },
        {
          id: 'menuim',
          to: (import.meta.env.VITE_FELO_IM as string) + '/' + (locale === 'en' ? '' : locale),
        },
        {
          id: 'menuglarity',
          to: 'https://glarity.app/',
        },
        {
          id: 'menucobol',
          to: 'https://cobol.felo.ai/',
        },
      ],
    },
  ];
};
